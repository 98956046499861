import React, {useEffect} from 'react';
import styled from 'styled-components';
import {Button} from 'antd';
import {useOutlet} from 'reconnect.js';
import {showAdminSearchStoreModal} from '../../Components/AdminSearchStoreModal/index.js';

export default function AdminSelectStoreWidget(props) {
  const [stores] = useOutlet('stores');
  const [selectedStore, setSelectedStore] = React.useState(null);

  React.useEffect(() => {
    if (props.value) {
      setSelectedStore(stores.find((x) => x.id === props.value));
    }
  }, [props.value, stores]);

  // React.useEffect(() => {
  //   (async() => {
  //     try {
  //       let resp = await AppActions.fetchStores({ allowAll: true });

  //     } catch(err) {
  //       console.log(err);
  //     }
  //   })()
  // }, [])

  return (
    <Wrapper>
      <div>
        {!props.value
          ? '尚未設定分店'
          : !selectedStore
          ? props.value
          : `【 ${selectedStore.name} 】`}
      </div>
      <Button
        onClick={() => {
          props.onChange('');
          setSelectedStore(null);
        }}
        style={{marginRight: 10}}>
        清除
      </Button>

      <Button
        type="primary"
        onClick={() =>
          showAdminSearchStoreModal({
            onChange: (id) => {
              props.onChange(id);
            },
          })
        }>
        搜尋
      </Button>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 14px;
  border: 1px solid #d9d9d9;
  padding: 15px;

  & > div {
    margin-right: 15px;
    letter-space: 1.5;
  }
`;
