import React from 'react';
import styled from 'styled-components';

function Item(props) {
  const {item, formData, className} = props;
  const [collapse, setCollapse] = React.useState(true);

  return (
    <div className={'rjsf-custom-array-item ' + className}>
      {collapse ? <div>{formData['name']}</div> : item.children}
      {item.hasToolbar && (
        <div className="toolbar">
          {collapse ? (
            <button
              onClick={() => setCollapse(false)}
              type="button"
              className="button">
              展
            </button>
          ) : (
            <button
              onClick={() => setCollapse(true)}
              type="button"
              className="button">
              合
            </button>
          )}
          {item.hasMoveUp ? (
            <button
              disabled={item.disabled || item.readonly}
              onClick={item.onReorderClick(item.index, item.index - 1)}
              className="button up"
              type="button">
              上
            </button>
          ) : (
            <button disabled className="button up" type="button">
              上
            </button>
          )}
          {item.hasMoveDown ? (
            <button
              disabled={item.disabled || item.readonly}
              onClick={item.onReorderClick(item.index, item.index + 1)}
              className="button down"
              type="button">
              下
            </button>
          ) : (
            <button disabled className="button up" type="button">
              下
            </button>
          )}
          {item.hasRemove ? (
            <button
              disabled={item.disabled || item.readonly}
              onClick={item.onDropIndexClick(item.index)}
              className="button delete"
              type="button">
              刪除
            </button>
          ) : (
            <button disabled className="button delete" type="button">
              刪除
            </button>
          )}
          <div className="index">{item.index + 1}</div>
        </div>
      )}
    </div>
  );
}

export default function ArrayFieldTemplate(props) {
  return (
    <Wrapper className={props.className}>
      {props.items.map((item) => (
        <Item
          className={props.uiSchema['items']?.['ui:className']}
          key={item.key}
          item={item}
          formData={props.formData[item.index]}
        />
      ))}
      {props.canAdd && (
        <button
          className="button add"
          disabled={props.disabled || props.readonly}
          type="button"
          onClick={props.onAddClick}>
          新增
        </button>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & .rjsf-custom-array-item {
    padding-left: 10px;
    margin-bottom: 10px;
    position: relative;
    min-height: 28px;
  }

  & .category-L1 {
    border-left: solid 1px yellowgreen;
  }

  & .category-L2 {
    background-color: beige;
  }

  & .category-L3 {
    background-color: palegoldenrod;
  }

  .button {
    background-color: olivedrab;
    color: #fff;
    border: solid 1px yellowgreen;
    border-radius: 4px;
    margin-left: 12px;
  }

  .button:disabled {
    opacity: 0.2;
  }

  & .toolbar {
    position: absolute;
    top: 0;
    right: 0px;

    & > .index {
      border: solid 1px khaki;
      color: darkkhaki;
      padding: 1px 8px;
      display: inline-block;
      border-radius: 4px;
      margin-left: 12px;
      width: 36px;
      text-align: right;
    }
  }
`;
