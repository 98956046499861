import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import * as AppActions from '../../AppActions';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import {useOutlet} from 'reconnect.js';
import {Button, Tooltip, message, Modal, Result, Alert} from 'antd';
import Config from '../../../data.json';
import * as PointUtil from '../../Utils/PointUtil';

export default function AdminUserMiddleSection(props) {
  const {context} = props;
  const {
    errors,
    formSpec,
    instance,
    position,
    values,
    setValues,
    extValues,
    setExtValues,
  } = context;
  const [points, setPoints] = React.useState([]);
  const user_id = instance?.id;
  const owner = instance?.owner;
  const [openDeleteConfirm, setOpenDeleteConfirm] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      if (instance?.id) {
        try {
          let resp = await JStorage.fetchAllDocuments('user_point', {
            owner: instance.owner,
            expired_timestamp: {$gte: Date.now()},
          });
          setPoints(resp);
        } catch (err) {
          console.log(err);
        }
      }
    })();
  }, [instance]);

  const point = React.useMemo(() => {
    let {point} = PointUtil.getPoint({points, user: instance});
    return point;
  }, [instance, points]);

  return !instance ? null : instance.status === 'DELETED' ? (
    <Alert
      showIcon
      type="error"
      message="此會員已被刪除"
      description="請勿對此會員資料進行操作"
    />
  ) : (
    <Wrapper>
      <div style={{padding: '0px 15px', lineHeight: '30px'}}>
        可用點數 {point}
      </div>
      <div className="divider" />

      <Button
        type="link"
        target="_blank"
        href={`/admin/add/points/?owner=${owner}`}>
        手動補點
      </Button>

      <div className="divider" />

      <Button
        type="link"
        target="_blank"
        href={`/admin/user_points/?owner=${owner}`}>
        點數紀錄
      </Button>

      <div className="divider" />

      <Button
        type="link"
        target="_blank"
        href={`/admin/point_exchange_histories/?owner=${owner}`}>
        點數兌換紀錄
      </Button>

      <div className="divider" />

      <Button type="link" target="_blank" href={`/admin/stamp/?owner=${owner}`}>
        印花集章
      </Button>

      <div className="divider" />

      <Button
        type="link"
        target="_blank"
        href={`/admin/coupon_instances/?owner=${owner}`}>
        優惠券
      </Button>

      <div className="divider" />

      <Button
        type="link"
        target="_blank"
        href={`/admin/store_orders/?owner=${owner}`}>
        訂單
      </Button>

      <div className="divider" />

      <Button
        type="link"
        danger
        onClick={async () => {
          setOpenDeleteConfirm(true);
        }}>
        刪除
      </Button>
      <Modal
        visible={openDeleteConfirm}
        closable
        onCancel={() => setOpenDeleteConfirm(false)}
        footer={null}>
        <Result
          status="warning"
          title={'確定要刪除此會員' + instance.name + '?'}
          subTitle={
            <div>
              <div>名稱:{instance.name}</div>
              <div>帳號(手機):{instance.phone}</div>
              <div>生日:{instance.birth}</div>
              <div>刪除後無法還原此會員</div>
            </div>
          }
          extra={[
            <Button onClick={() => setOpenDeleteConfirm(false)}>取消</Button>,
            <Button
              danger
              onClick={async () => {
                try {
                  AppActions.setLoading(true);
                  await AppActions.deleteUser({sub: owner});
                  message.success('已成功刪除');
                  AppActions.navigate('/admin/users');
                } catch (error) {
                  console.log(error);
                  message.error('發生錯誤');
                }
                AppActions.setLoading(false);
              }}>
              確認刪除
            </Button>,
          ]}
        />
      </Modal>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  border: solid 1px #ddd;
  padding: 10px;
  border-radius: 30px;
  margin-bottom: 20px;

  & .divider {
    background-color: #ddd;
    width: 1px;
    align-self: stretch;
  }
`;
