export function getPoint({points: _points, user}) {
  const now = Date.now();
  const pointRecords = _points.filter(
    (p) => user.owner === p.owner && p.expired_timestamp >= now,
  );
  const point = pointRecords.reduce((sum, p) => {
    sum += p.point - p.usage;
    return sum;
  }, 0);

  return {
    point,
  };
}
