import React from 'react';
import styled from 'styled-components';
import * as Ant from 'antd';
import AdminFileUploadWidget from '../../Generators/AdminResource/AdminFileUploadWidget';
import {Helmet} from 'react-helmet';
import spec from '../../../content/admin/coupon_code.json';
import {Attachment} from '@styled-icons/icomoon/Attachment';
import * as Theme from '../../Theme';
import * as AppActions from '../../AppActions';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import qs from 'query-string';

const initValue = {
  template: '',
  code: '',
  circulation: 0,
  restrict_circulation: -1,
  owner_phones: [],
  note: '',
};

function checkPhone(phone) {
  return /^09[0-9]{8}$/g.test(phone);
}

export default function CouponCodeTopSection(props) {
  const {context, location} = props;
  const {template} = qs.parse(location.search);
  const [record, _setRecord] = React.useState({...initValue});
  const [couponTemplate, setCouponTemplate] = React.useState();

  const setRecord = React.useCallback(
    (value) => _setRecord((prev) => ({...prev, ...value})),
    [],
  );

  React.useEffect(() => {
    setRecord({template: template});

    (async () => {
      try {
        let couponTemplate = await JStorage.fetchOneDocument(
          'coupon_template',
          {
            id: template,
          },
        );
        setCouponTemplate(couponTemplate);
      } catch (e) {
        console.warn(e);
      }
    })();
  }, [template]);

  function importExcel(file) {
    return new Promise((resolve, reject) => {
      try {
        const reader = new FileReader();
        reader.onload = (e) => {
          try {
            const data = new Uint8Array(e.target.result);
            const wb = window.XLSX.read(data, {
              bookType: 'xlsx',
              bookSST: false,
              type: 'array',
            });

            const ws = wb.Sheets[wb.SheetNames[0]];
            const result = window.XLSX.utils.sheet_to_json(ws, {
              raw: false, // force to parse as string format to avoid date mis-parsing
            });

            resolve(result);
          } catch (ex) {
            console.warn('readXslFile', ex);
            reject(ex);
          }
        };
        reader.readAsArrayBuffer(file);
      } catch (ex) {
        console.warn(ex);
        resolve(null);
      }
    });
  }

  const submit = async () => {
    if (!record.code) {
      return Ant.message.warning('代碼為必填');
    }
    if (
      typeof record.restrict_circulation === 'undefined' ||
      record.restrict_circulation === ''
    ) {
      return Ant.message.warning('兌換上限次數為必填');
    }
    if (record.code?.length > 10) {
      return Ant.message.warning('代碼設定上限10個字元');
    }
    if (
      record.owner_phones?.length > 0 &&
      record.owner_phones.some((x) => !checkPhone(x))
    ) {
      return Ant.message.warning('手機格式錯誤');
    }
    AppActions.setLoading(true);
    try {
      await JStorage.createDocument(spec.collection, record);
      Ant.message.success('新增成功');
      _setRecord({...initValue, template: template});
      context.setQueryState({...context.queryState, extraQueries: {}}); // force refresh
    } catch (e) {
      console.warn(e);
      if (
        e?.response?.error === 'db_error' &&
        e?.response?.detail.indexOf('duplicate key') > -1
      ) {
        Ant.message.error('發生錯誤，有重複的代碼');
      } else {
        Ant.message.error('發生錯誤');
      }
    }
    AppActions.setLoading(false);
  };

  const bulkwrite = async (_data) => {
    let data = [..._data];
    AppActions.setLoading(true);
    try {
      if (!Array.isArray(data)) {
        throw new Error('格式錯誤');
      }
      if (data?.length === 0) {
        throw new Error('資料長度 = 0');
      }

      data = data.map((item) => {
        let owner_phones = (item.owner_phones || '')
          .split('\n')
          .map((x) => x.trim());
        if (!owner_phones[0]) {
          owner_phones = [];
        }
        return {...item, owner_phones};
      });

      for (let item of data) {
        if (!item.code) {
          throw new Error('代碼為必填');
        }
        if (
          typeof item.restrict_circulation === 'undefined' ||
          item.restrict_circulation === ''
        ) {
          throw new Error('兌換上限次數為必填');
        }
        if (item.code.length > 10) {
          throw new Error(item.code + ' 代碼設定上限10個字元');
        }
        if (
          item.owner_phones.length > 0 &&
          item.owner_phones.some((x) => !checkPhone(x))
        ) {
          throw new Error(item.code + ' 手機格式錯誤');
        }
      }

      let operations = data.map((x) => ({
        method: 'insert_one',
        payload: {
          query: {},
          data: {
            template: template,
            code: x.code,
            circulation: 0,
            restrict_circulation: parseInt(x.restrict_circulation),
            owner_phones: x.owner_phones || [],
            note: x.note,
          },
        },
      }));

      await JStorage.bulkWriteDocuments(spec.collection, operations);
      Ant.message.success('新增成功');
      _setRecord({...initValue, template: template});
      context.setQueryState({...context.queryState, extraQueries: {}}); // force refresh
    } catch (e) {
      console.warn(e);
      if (
        e?.response?.error === 'db_error' &&
        e?.response?.detail.indexOf('duplicate key') > -1
      ) {
        const code = e?.response?.results?.writeErrors?.[0]?.op?.code;
        Ant.message.error('發生錯誤，有重複的代碼' + code);
      } else {
        Ant.message.error('發生錯誤 ' + e?.message);
      }
    }
    AppActions.setLoading(false);
  };

  return (
    <Wrapper>
      <Helmet>
        <script src="https://cdn.sheetjs.com/xlsx-latest/package/dist/xlsx.full.min.js" />
      </Helmet>
      <h1>{spec.name}</h1>

      <h2>單筆新增</h2>
      <Ant.Row>
        <Ant.Col span={2} className="label">
          優惠券樣板
        </Ant.Col>
        <Ant.Col span={6}>
          <div>
            <div>{record.template || '找不到，請從正確的路徑開啟此頁'}</div>
            <div>{couponTemplate?.name}</div>
            <div>{couponTemplate?.event_name}</div>
          </div>
        </Ant.Col>
        <Ant.Col span={2} className="label">
          代碼設定
        </Ant.Col>
        <Ant.Col
          span={6}
          style={{flexDirection: 'column', alignItems: 'flex-start'}}>
          <Ant.Input
            value={record.code}
            onChange={(e) => setRecord({code: e.target.value})}
          />
          <div className="hint">
            代碼設定需為10個中英文數字內，不含特殊符號{' '}
          </div>
          <div className="hint">不可與存在的代碼重複</div>
        </Ant.Col>

        <Ant.Col span={2} className="label">
          兌換上限次數
        </Ant.Col>
        <Ant.Col
          span={6}
          style={{
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'center',
          }}>
          <Ant.Input
            value={record.restrict_circulation}
            onChange={(e) => {
              const value = parseInt(e.target.value);
              setRecord({restrict_circulation: value});
            }}
            min={-1}
            type="number"
            style={{width: 100}}
          />{' '}
          <div className="hint">
            {' '}
            -1=沒有上限，多筆情況（適用對象3人，每人用一次，請填入3）
          </div>
        </Ant.Col>
      </Ant.Row>
      <Ant.Row>
        <Ant.Col span={2} className="label">
          適用對象
        </Ant.Col>
        <Ant.Col
          span={22}
          style={{
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'center',
          }}>
          <Ant.Input
            placeholder="請填入用戶手機，無指定不填，請以,(半形逗號)分隔"
            value={(record.owner_phones || []).join(',')}
            onChange={(e) => {
              let value = e.target.value || '';
              let owner_phones = value.split(',').map((x) => x.trim());
              if (!owner_phones[0]) {
                owner_phones = [];
              }
              setRecord({owner_phones});
            }}
          />

          <div className="hint">請以,(半形逗號)分隔</div>
        </Ant.Col>
      </Ant.Row>
      <Ant.Row>
        <Ant.Col span={2} className="label">
          代碼備註
        </Ant.Col>
        <Ant.Col span={22}>
          <Ant.Input
            value={record.note}
            onChange={(e) => setRecord({note: e.target.value})}
          />
        </Ant.Col>
      </Ant.Row>

      <div style={{display: 'flex', marginTop: 10}}>
        <div style={{flex: 1}} />
        <Ant.Button type="primary" onClick={submit} disabled={!template}>
          確認
        </Ant.Button>
      </div>

      <h2>批次新增</h2>

      <Ant.Row>
        <Ant.Col span={2} className="label">
          批次新增
        </Ant.Col>
        <Ant.Col span={14}>
          <AdminFileUploadWidget
            disabled={!template}
            onCustomChange={async ({file}) => {
              let data;
              try {
                data = await importExcel(file);
              } catch (error) {
                Ant.message.error('無法解析此檔案');
              }
              if (data) {
                await bulkwrite(data);
              }
            }}
          />
        </Ant.Col>
        <Ant.Col span={2} className="label">
          下載範本
        </Ant.Col>
        <Ant.Col span={6} style={{flexDirection: 'column'}}>
          <Ant.Button
            type="link"
            href={'/coupon-code.xlsx'}
            style={{padding: 0}}
            target="_blank"
            download>
            <Attachment
              color={Theme.colors.main}
              size={12}
              style={{marginRight: 4}}
            />
            下載範本EXCEL
          </Ant.Button>
          <div style={{color: '#ccc'}}>請先下載此Excel檔案範本編輯後上傳</div>
        </Ant.Col>
      </Ant.Row>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-bottom: 20px;
  & h1 {
    font-size: 32px;
  }

  & h2 {
    margin: 10px 0;
  }

  .ant-row {
    border-left: solid 1px #ccc;
    border-top: solid 1px #ccc;
  }
  .ant-row + .ant-row {
    border-top: 0;
  }

  .ant-col {
    padding: 6px;
    display: flex;
    align-items: center;
    border-right: solid 1px #ccc;
    border-bottom: solid 1px #ccc;
  }

  .label {
    background-color: #ffe6a1;
  }

  .hint {
    color: #ccc;
  }
`;
