import React from 'react';
import {Button, Upload} from 'antd';
import {Attachment} from '@styled-icons/icomoon/Attachment';
import * as Theme from '../../Theme';
import * as StorageActions from 'rev.sdk.js/Actions/Storage';
import * as AppActions from '../../AppActions';

const initialValue = {
  url: null,
  data: {},
  filename: null,
};

export default function AdminFileUploadWidget(props) {
  const {value, onChange, sample, onCustomChange, disabled} = props;
  const [fileList, setFileList] = React.useState([]);

  const [file, setFile] = React.useState({...initialValue});

  const beforeUpload = async (file) => {
    try {
      AppActions.setLoading(true);
      let resp = await StorageActions.getUploadUrlFromFile(file);
      setFile({
        url: resp.url,
        data: resp.fields,
        filename: resp.expected,
      });
    } catch (err) {
      alert('Api error');
      AppActions.setLoading(false);
    }
  };

  const handleChange = async (info) => {
    if (info.file.status === 'uploading') {
      return;
    }
    if (info.file.status === 'done') {
      AppActions.setLoading(false);
      alert(`成功! ${file.filename}`);
      setFile({...initialValue});
      if (onChange) {
        onChange(file.filename);
      }
    }
  };

  return (
    <div style={{fontSize: 14}}>
      <div>{value}</div>

      {typeof onCustomChange === 'function' ? (
        <div style={{display: 'flex', alignItems: 'center'}}>
          {fileList[0] ? (
            <React.Fragment>
              <Attachment size={12} style={{marginRight: 4}} />
              <div style={{marginRight: 10}}>{fileList[0]?.name}</div>
            </React.Fragment>
          ) : null}
          <Upload
            name="file"
            showUploadList={false}
            beforeUpload={(file) => {
              setFileList([file]);
              return false;
            }}
            onRemove={() => setFileList([])}
            onChange={() => onCustomChange({file: fileList[0]})}
            disabled={disabled}>
            <Button disabled={disabled}>上傳檔案</Button>
          </Upload>
        </div>
      ) : (
        <Upload
          name="file"
          showUploadList={false}
          action={file.url}
          beforeUpload={beforeUpload}
          onChange={handleChange}
          data={file.data}
          disabled={disabled}>
          <Button disabled={disabled}>上傳檔案</Button>
        </Upload>
      )}

      {/* {sample && (
        <React.Fragment>
          <Button
            type="link"
            href={sample}
            style={{padding: 0}}
            target="_blank"
            download>
            <Attachment
              color={Theme.colors.main}
              size={12}
              style={{marginRight: 4}}
            />
            下載範本EXCEL
          </Button>
          <div style={{color: '#ccc'}}>請先下載此Excel檔案範本編輯後上傳</div>
        </React.Fragment>
      )} */}
    </div>
  );
}
