import React from 'react';
import styled from 'styled-components';
import {Button, message} from 'antd';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import {getNewOutlet, useOutlet} from 'reconnect.js';
import * as Ant from 'antd';
import * as AppActions from '../../AppActions';

getNewOutlet('LayaPosUserListModal', null, {autoDelete: false});

export default function PosUserListModal(props) {
  const [modalData, setModalData] = useOutlet('LayaPosUserListModal');
  return (
    <Ant.Modal
      title={null}
      footer={null}
      bodyStyle={{padding: 0}}
      width={480}
      visible={modalData}
      onOk={() => {
        setModalData(null);
      }}
      onCancel={() => {
        setModalData(null);
      }}
      destroyOnClose={true}>
      {modalData && <ModalContent modalData={modalData} />}
    </Ant.Modal>
  );
}

function ModalContent(props) {
  const {modalData} = props;
  const {store} = modalData;
  const [posUsers, setPosUsers] = React.useState([]);
  const [newValues, setNewValues] = React.useState({
    username: '',
    password: '',
  });

  const fetchPosUsers = React.useCallback(async () => {
    AppActions.setLoading(true);
    try {
      setPosUsers(
        await JStorage.fetchAllDocuments('pos_user', {store_id: store.id}),
      );
    } catch (ex) {
      console.warn(ex);
    }
    AppActions.setLoading(false);
  }, [store]);

  React.useEffect(() => {
    fetchPosUsers();
  }, [fetchPosUsers]);

  async function createPosUser() {
    if (!newValues.username || !newValues.password) {
      window.alert('請填入所有欄位');
      return;
    }

    AppActions.setLoading(true);
    try {
      await AppActions.delay(600);
      await AppActions.createPosUser({
        username: newValues.username,
        password: newValues.password,
        store_id: store.id,
      });
      setNewValues({
        username: '',
        password: '',
      });
      message.success('成功');
      await fetchPosUsers();
    } catch (ex) {
      console.warn('LayaPosUserListModal.createPosUser ex', ex);
      message.error('無法完成操作, 請稍後再試一次');
    }
    AppActions.setLoading(false);
  }

  return (
    <Wrapper>
      <h2>使用者列表-{store.name}</h2>

      <div className="form">
        <div className="field">
          <label>帳號</label>
          <input
            value={newValues.username}
            onChange={(e) =>
              setNewValues({...newValues, username: e.target.value})
            }
          />
        </div>

        <div className="field">
          <label>密碼</label>
          <input
            value={newValues.password}
            onChange={(e) =>
              setNewValues({...newValues, password: e.target.value})
            }
          />
        </div>

        <div className="action">
          <Button type="primary" onClick={createPosUser}>
            建立新使用者
          </Button>
        </div>
      </div>

      <h3 style={{margin: '20px 0', borderBottom: '1px solid #ccc'}}>
        所有帳戶
      </h3>

      <div className="pos-users">
        {posUsers.map((posUser) => {
          return (
            <div key={posUser.id}>
              <div className="username">{posUser.username}</div>
              <Button
                size="small"
                onClick={async () => {
                  let password = window.prompt('請輸入密碼');
                  if (password) {
                    AppActions.setLoading(true);
                    try {
                      let {token, refresh} = await AppActions.loginPosUser({
                        username: posUser.username,
                        password,
                        store_id: store.id,
                      });
                      navigator.clipboard.writeText(token);
                      message.success('已複製到剪貼簿');
                    } catch (err) {
                      console.log(err);
                    }
                    AppActions.setLoading(false);
                  }
                }}>
                測試憑證
              </Button>
              <Button
                size="small"
                style={{marginLeft: 6}}
                onClick={async () => {
                  const newPassword = window.prompt('請輸入新密碼');
                  if (newPassword) {
                    AppActions.setLoading(true);
                    try {
                      await AppActions.adminResetPosUserPassword({
                        username: posUser.username,
                        password: newPassword,
                        store_id: store.id,
                      });
                      await fetchPosUsers();
                    } catch (ex) {
                      message.error('無法完成操作, 請稍後再試一次');
                    }
                    AppActions.setLoading(false);
                  }
                }}>
                修改密碼
              </Button>

              <Button
                size="small"
                style={{marginLeft: 6}}
                onClick={async () => {
                  if (window.confirm(`確認要刪除${posUser.username}嗎?`)) {
                    AppActions.setLoading(true);
                    try {
                      await AppActions.adminDeletePosUserPassword(posUser.id);
                      await fetchPosUsers();
                    } catch (ex) {
                      message.error('無法完成操作, 請稍後再試一次');
                    }
                    AppActions.setLoading(false);
                  }
                }}>
                刪除帳號
              </Button>
            </div>
          );
        })}
      </div>

      {posUsers.length === 0 && (
        <div style={{margin: '20px 0', color: '#888'}}>目前尚無使用者</div>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 20px;

  & > .pos-users {
    & > * {
      padding: 10px;
      margin-bottom: 15px;
      border: 1px solid #ccc;
      border-radius: 6px;
      display: flex;
      align-items: center;

      & > .username {
        font-size: 16px;
        flex: 1;
      }
    }
  }

  & > .form {
    margin-top: 20px;
    padding: 10px;
    border-radius: 6px;
    background-color: #eee;

    & .field {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      & label {
        margin-right: 10px;
      }
    }

    & .action {
      display: flex;
      justify-content: flex-end;
    }
  }
`;
