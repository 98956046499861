import React from 'react';
import qs from 'query-string';

export default function AdminStoreRelatedSection(props) {
  const {location, context} = props;
  const {
    errors,
    formSpec,
    instance,
    position,
    values,
    setValues,
    extValues,
    setExtValues,
  } = context;
  const params = qs.parse(location.search);
  const {store_id} = params;
  const key =
    location.pathname.indexOf('admin/store_promotions') > -1
      ? 'store'
      : location.pathname.indexOf('admin/store_secret') > -1
      ? 'store_id'
      : '';

  React.useEffect(() => {
    if (params.action === 'create') {
      setTimeout(() => {
        setValues((prev) => ({...prev, [key]: store_id}));
      }, 1000);
    }
  }, [store_id, location.pathname, params.action]);

  return params.action === 'create' ? (
    <div style={{marginBottom: 10}}>
      {store_id && store_id !== values[key]
        ? ''
        : store_id
        ? '已自動帶入分店編號 ' + store_id
        : '無法自動帶入分店資訊'}
    </div>
  ) : null;
}
