import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import * as AppActions from '../../AppActions';
import RichTextEditor from 'rev.sdk.js/Components/RichTextEditor';
import {
  generateHtml,
  upsertArticle,
} from 'rev.sdk.js/Components/ArticleEditor/ArticleEditorUtil';
import * as Ant from 'antd';

export default function ArticleBelowSection({name, type, context, path}) {
  const {position, instance, values, setValues, errors, formSpec} = context;
  const {schema, uiSchema, errorSchema} = formSpec;
  const [content, setContent] = useState();

  useEffect(() => {
    if (instance) {
      setContent(instance.content);
    }
  }, [instance]);

  const submit = async () => {
    // get article content & html

    const formData = {
      ...values,
      content,
      html: await generateHtml(content),
    };

    await upsertArticle(formData, instance, {
      onUpdate: (createdDoc) =>
        AppActions.navigate(path + `?action=detail&id=${createdDoc.id}`),
    });
  };

  return (
    <Wrapper>
      <h2>文章內容</h2>
      <div className="content">
        <RichTextEditor
          onChange={(value) => setContent(value)}
          content={content}
        />
      </div>
      <div className="footer">
        {instance && (
          <div>
            建立：{new Date(instance.created).toLocaleString('sv')}
            {' / '}
          </div>
        )}
        {instance && (
          <div>
            更新：{new Date(instance.updated).toLocaleString('sv')}
            {' / '}
          </div>
        )}
        {instance && <div>{instance.id}</div>}

        <div style={{flex: 1}} />
        <Ant.Button
          type="primary"
          onClick={() => {
            submit();
          }}>
          {instance ? '編輯' : '新增'}
        </Ant.Button>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-top: 25px;
  margin-bottom: 30px;
  border-top: 1px solid var(--primaryColor);

  & > h2 {
    margin: 15px 0;
  }

  & .content {
    padding: 8px;
    background-color: #fff;
    margin-bottom: 30px;
  }

  & > .footer {
    display: flex;
    align-items: center;

    & > div {
      color: #aaa;
      font-size: 12px;
      margin-right: 5px;
    }
  }
`;
